<template lang="">
  <div
    id="search-class-filter"
    class="d-flex-center"
  >
    <img
      src="@/assets/other-icon/seat.svg"
      class="mr-25"
    >
    <v-select
      :value="cabinRef"
      class="w-100"
      :reduce="val => val.value"
      :options="cabinPreferenceOptions"
      label="label"
      :disabled="isDisabled"
      :placeholder="$t('flight.classFilterSearchPlaceholder')"
      @input="updateCabinPreference"
    >
      <template #selected-option="data">
        <div>{{ $t(`flight.classFilter.${data.label}`) }}</div>
      </template>

      <template #option="data">
        <div>{{ $t(`flight.classFilter.${data.label}`) }}</div>
      </template>
    </v-select>
  </div>
</template>
<script>
import vSelect from 'vue-select'
import { ref, watch } from '@vue/composition-api'

import { cabinPreferenceOptions } from '@/constants/selectOptions'

export default {
  components: {
    vSelect,
  },
  props: {
    cabinPreference: {
      type: [Object, undefined],
      default: () => undefined,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const cabinRef = ref(props.cabinPreference?.cabin)

    function updateCabinPreference(val) {
      cabinRef.value = val
      if (val) {
        emit('update:cabinPreference', {
          preferenceType: 'PERMITTED',
          cabin: val,
        })
      } else {
        emit('update:cabinPreference', undefined)
      }
    }

    // clear khi chọn hạng vé, vé rẻ theo tháng
    watch(() => props.isDisabled, val => {
      if (val) updateCabinPreference()
    })
    return {
      cabinRef,
      cabinPreferenceOptions,
      updateCabinPreference,
    }
  },
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables/_variables.scss';

#search-class-filter ::v-deep {
  .vs__dropdown-toggle {
    border: none;
    padding: auto 0;
  }
  .vs__open-indicator {
    display: none;
  }
}
</style>
