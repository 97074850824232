var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex-center",
    attrs: {
      "id": "search-class-filter"
    }
  }, [_c('img', {
    staticClass: "mr-25",
    attrs: {
      "src": require("@/assets/other-icon/seat.svg")
    }
  }), _c('v-select', {
    staticClass: "w-100",
    attrs: {
      "value": _vm.cabinRef,
      "reduce": function reduce(val) {
        return val.value;
      },
      "options": _vm.cabinPreferenceOptions,
      "label": "label",
      "disabled": _vm.isDisabled,
      "placeholder": _vm.$t('flight.classFilterSearchPlaceholder')
    },
    on: {
      "input": _vm.updateCabinPreference
    },
    scopedSlots: _vm._u([{
      key: "selected-option",
      fn: function fn(data) {
        return [_c('div', [_vm._v(_vm._s(_vm.$t("flight.classFilter.".concat(data.label))))])];
      }
    }, {
      key: "option",
      fn: function fn(data) {
        return [_c('div', [_vm._v(_vm._s(_vm.$t("flight.classFilter.".concat(data.label))))])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }